import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const ProtectedRoutes = ({ isUserLogged }) => {
  const location = useLocation();
  const pathname = location.pathname;

  if (isUserLogged && (pathname === '/' || pathname === '/login')) {
    return <Navigate to="home" />;
  }

  if (
    !isUserLogged &&
    pathname !== '/' &&
    pathname !== '/agreement' &&
    pathname !== '/createAccount' &&
    pathname !== '/resetPassword' &&
    pathname !== '/deleteAccountConfirm' &&
    pathname !== '/forgotCode'
  ) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default ProtectedRoutes;
