import React from 'react';
import MediaQuery from 'react-responsive';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import { TABLET_MIN_WIDTH } from '../../helpers/mediaQueries';
import { useGlobalStore } from '../../stores/globalStore';
import Icon from '../Icon';
import SideMenu from './SideMenu';
import Sidebar from './Sidebar';
import { Outlet } from 'react-router-dom';
import useUser from '../../api/hooks/useUser';

const AppLayout = ({ children, intl }) => {
  return (
    <div className="App">
      <AppBar intl={intl} />

      <div className="page container">
        <Sidebar />

        <div className="page-wrapper">
          <>
            <Outlet />
            <a
              className="policy__link"
              href="/static/pdf/SC_%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0_%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8_%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85_%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85_2023_03_24.pdf"
            >
              Политика обработки персональных данных
            </a>
          </>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(AppLayout);

const AppBar = ({ intl }) => {
  const isSideMenuActive = useGlobalStore(state => state.isSideMenuActive);
  const setSideMenuActive = useGlobalStore(state => state.setSideMenuActive);
  const _ = id => intl.formatHTMLMessage({ id });

  const handleSideMenuToggle = () => {
    setSideMenuActive(!isSideMenuActive);

    if (!isSideMenuActive) {
      window.gaEvent('menu/open', 'ui_click', false);
    }
  };
  const { user } = useUser();

  return (
    <div className="appbar">
      <div className="container">
        <div className="burger-menu">
          <div className="toggleMenu">
            <button onClick={handleSideMenuToggle}>
              <Icon name={isSideMenuActive ? 'close' : 'menu'} />
              <MediaQuery minDeviceWidth={TABLET_MIN_WIDTH}>
                <AppBarTitle>
                  {_(isSideMenuActive ? 'btn_close_pop_up' : 'nav_menu_title')}
                </AppBarTitle>
              </MediaQuery>
            </button>
          </div>
        </div>

        <SideMenu
          login={user && user.login}
          lastLogin={user && user.lastLogin}
          isSideMenuActive={isSideMenuActive}
        />
      </div>
    </div>
  );
};

const Title = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #333333;
`;

const AppBarTitle = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #333333;
`;
