import React from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useUser from './../api/hooks/useUser';

const HomeProfile = props => {
  const { intl } = props;
  const _ = id => intl.formatHTMLMessage({ id });

  const { user } = useUser();

  if (!user) return null;

  return (
    <Profile>
      <ProfileWrapper>
        <UserName>{user.login}</UserName>

        <UserStats>
          <p>
            {user.profile.size} {_('unit_size_main')}
          </p>
          <p>
            {user.profile.weight} {_('unit_weight')}
          </p>
        </UserStats>

        <Link to="/myProfile">
          <ActionButton>{_('settings_pgm_edit_action')}</ActionButton>
        </Link>
      </ProfileWrapper>
    </Profile>
  );
};

export default injectIntl(HomeProfile);

const UserName = styled.p`
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #333333;

  text-overflow: ellipsis;
  overflow: hidden;
`;

const UserStats = styled.p`
  padding: 10px 0 20px 0;

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #333333;
  }
`;

const Profile = styled.div`
  margin-bottom: 40px;
`;

const ProfileWrapper = styled.div`
  padding: 20px;
  text-align: center;
  color: #333333;
  background: #ffffff;
  border-radius: 26px;
`;

const ActionButton = styled.div`
  background: linear-gradient(270deg, #d5edf1 0%, #b5cfd3 100%), #ffffff;
  border-radius: 20px;
  padding: 8px 16px;
  width: 100%;

  font-weight: 600;
  font-size: 10px;
  line-height: 13px;

  text-align: center;
  letter-spacing: 0.25em;
  text-transform: uppercase;

  color: #333333;
`;
