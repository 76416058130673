import React from 'react'
import { useQuery } from 'react-query'
import database from '../../helpers/database'

const useUser = () => {
  const { data, isLoading, refetch } = useQuery(
    ['user'], () => database.app.get('user'),
    {
      select: ({ User, PatientProfile }) => ({
        login: User ? User.login : '',
        lastLogin: User ? User.modified : '',
        profile: {
          size: PatientProfile ? PatientProfile.size : '',
          weight: PatientProfile ? PatientProfile.weight : ''
        }
      })
    }
  )

  return { 
    user: data,
    isLoading,
    refetch
  }
}

export default useUser
