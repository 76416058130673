export const DESKTOP_MIN_WIDTH = 1025
export const TABLET_MAX_WIDTH = 1024
export const MOBILE_MAX_WIDTH = 480
export const TABLET_MIN_WIDTH = 768

export const desktopWidth = { minDeviceWidth: DESKTOP_MIN_WIDTH }
export const tabletWidth = { minDeviceWidth: TABLET_MIN_WIDTH, maxWidth: TABLET_MAX_WIDTH }
export const mobileWidth = { maxDeviceWidth: MOBILE_MAX_WIDTH }

export const MOBILE_DEVICES = 575.98
export const SMALL_DEVICES = 767.98
export const MEDIUM_DEVICES = 991.98
export const LARGE_DEVICES = 1199.98